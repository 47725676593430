import { winSound } from '@/common/sounds';
import { Button } from '@/components/button';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
} from '@/components/dialog';
import { Points } from '@/components/points';
import { Score } from '@/components/score';
import {
  useCallback,
  useEffect,
} from 'react';
import Particles from 'react-particles';
import styled from 'styled-components';
import { loadFull } from 'tsparticles';
import type { Engine } from 'tsparticles-engine';
import { ISourceOptions } from 'tsparticles-engine';

type DialogProgressProps = Pick<DialogProps, 'isOpen'> & {
  answer: string;
  isLastQuestion: boolean;
  onEnd: () => void;
  onNext: () => void;
  points: number;
  score: number;
};


const options: ISourceOptions = {
  fullScreen: {
    zIndex: 1,
  },
  particles: {
    color: {
      value: [
        '#f0f',
        '#f00',
        '#ff0',
        '#0f0',
        '#0ff',
        '#00f',
      ],
    },
    move: {
      direction: 'bottom',
      enable: true,
      outModes: {
        default: 'out',
      },
      size: true,
      speed: {
        min: 1,
        max: 3,
      },
    },
    number: {
      value: 100,
      density: {
        enable: true,
        area: 800,
      },
    },
    opacity: {
      value: 1,
      animation: {
        enable: false,
        startValue: 'max',
        destroy: 'min',
        speed: 0.3,
        sync: true,
      },
    },
    rotate: {
      value: {
        min: 0,
        max: 360,
      },
      direction: 'random',
      move: true,
      animation: {
        enable: true,
        speed: 60,
      },
    },
    tilt: {
      direction: 'random',
      enable: true,
      move: true,
      value: {
        min: 0,
        max: 360,
      },
      animation: {
        enable: true,
        speed: 60,
      },
    },
    shape: {
      type: [
        'square',
      ],
    },
    size: {
      value: {
        min: 6,
        max: 12,
      },
    },
    roll: {
      darken: {
        enable: true,
        value: 30,
      },
      enlighten: {
        enable: true,
        value: 30,
      },
      enable: true,
      speed: {
        min: 15,
        max: 25,
      },
    },
    wobble: {
      distance: 30,
      enable: true,
      move: true,
      speed: {
        min: -15,
        max: 15,
      },
    },
  },
};

export const DialogProgress = ({
  answer,
  isLastQuestion,
  isOpen,
  onEnd,
  onNext,
  points,
  score,
}: DialogProgressProps) => {
  useEffect(() => {
    if (isOpen) {
      winSound.play();
    }
  }, [isOpen]);

  const particlesInit = useCallback(async (engine: Engine) => {
    await loadFull(engine);
  }, []);

  return (
    <Dialog isOpen={isOpen}>
      <DialogTitle>Gratulacje!</DialogTitle>
      <DialogContent>
        <S.Message>Poprawna odpowiedź to:</S.Message>
        <S.Title>{answer.toUpperCase()}</S.Title>
        <Points points={points} />
        {isLastQuestion && (
          <>
            <S.Message>Zdobywasz:</S.Message>
            <Score score={score} />
          </>
        )}
      </DialogContent>
      <DialogActions>
        {isLastQuestion && (
          <>
            <Button onClick={onNext} color="green">Nowa gra</Button>
            <Button onClick={onEnd} color="red">Koniec</Button>
          </>
        )}
        {!isLastQuestion && <Button onClick={onNext}>Dalej</Button>}
      </DialogActions>
      {isLastQuestion && <Particles init={particlesInit} options={options} />}
    </Dialog>
  );
};

const S = {
  Title: styled.h2`
    font-family: 'Sriracha', cursive;
    font-size: 4.5vmin;
    text-shadow: 0 0.5vmin 0 rgba(0, 0, 0, 0.3);
    margin: 0;
  `,
  Message: styled.p`
    font-family: 'Sriracha', cursive;
    font-size: 3vmin;
    text-shadow: 0 0.4vmin 0 rgba(0, 0, 0, 0.3);
    margin: 0;
  `,
};