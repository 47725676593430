import { Point } from '@/components/point';
import styled from 'styled-components';

type ScoreProps = {
  score: number;
}

export const Score = ({ score }: ScoreProps) => {
  return (
    <S.Score>
      <S.Value>{score}</S.Value>
      <Point filled={true} />
    </S.Score>
  );
};

const S = {
  Score: styled.div`
    display: flex;
    gap: 1vmin;
    align-items: center;
  `,
  Value: styled.div`
    color: #fff;
    font-family: 'Sriracha', cursive;
    font-size: 4.5vmin;
    text-shadow: 0 0.5vmin 0 rgba(0, 0, 0, 0.3);
  `,
};