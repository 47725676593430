import { Difficulty } from '@/common/types';
import { Button } from '@/components/button';
import {
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
} from '@/components/dialog';

type DialogProgressProps = Pick<DialogProps, 'isOpen'> & {
  onSelectDifficulty: (difficulty: Difficulty) => void;
};
export const DialogPreGame = ({
  isOpen,
  onSelectDifficulty,
}: DialogProgressProps) => {
  return (
    <Dialog isOpen={isOpen}>
      <DialogTitle>Wybierz poziom trudności</DialogTitle>
      <DialogActions>
        <Button onClick={() => onSelectDifficulty('easy')} color="green">Łatwy</Button>
        <Button onClick={() => onSelectDifficulty('medium')}>Średni</Button>
        <Button onClick={() => onSelectDifficulty('hard')} color="red">Trudny</Button>
      </DialogActions>
    </Dialog>
  );
};