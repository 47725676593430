import gepard from '../assets/images/animals/gepard.svg';
import hipopotam from '../assets/images/animals/hipopotam.svg';
import jelen from '../assets/images/animals/jelen.svg';
import jez from '../assets/images/animals/jez.svg';
import kameleon from '../assets/images/animals/kameleon.svg';
import kangur from '../assets/images/animals/kangur.svg';
import kon from '../assets/images/animals/kon.svg';
import kot from '../assets/images/animals/kot.svg';
import koza from '../assets/images/animals/koza.svg';
import krokodyl from '../assets/images/animals/krokodyl.svg';
import krowa from '../assets/images/animals/krowa.svg';
import kura from '../assets/images/animals/kura.svg';
import leniwiec from '../assets/images/animals/leniwiec.svg';
import lew from '../assets/images/animals/lew.svg';
import malpa from '../assets/images/animals/malpa.svg';
import mewa from '../assets/images/animals/mewa.svg';
import mrowkojad from '../assets/images/animals/mrowkojad.svg';
import niedzwiedz from '../assets/images/animals/niedzwiedz.svg';
import nosorozec from '../assets/images/animals/nosorozec.svg';
import owca from '../assets/images/animals/owca.svg';
import pancernik from '../assets/images/animals/pancernik.svg';
import panda from '../assets/images/animals/panda.svg';
import paw from '../assets/images/animals/paw.svg';
import pelikan from '../assets/images/animals/pelikan.svg';
import pies from '../assets/images/animals/pies.svg';
import pingwin from '../assets/images/animals/pingwin.svg';
import sep from '../assets/images/animals/sep.svg';
import slon from '../assets/images/animals/slon.svg';
import strus from '../assets/images/animals/strus.svg';
import swinia from '../assets/images/animals/swinia.svg';
import szczur from '../assets/images/animals/szczur.svg';
import szop from '../assets/images/animals/szop.svg';
import tukan from '../assets/images/animals/tukan.svg';
import tygrys from '../assets/images/animals/tygrys.svg';
import waz from '../assets/images/animals/waz.svg';
import wielblad from '../assets/images/animals/wielblad.svg';
import wol from '../assets/images/animals/wol.svg';
import zebra from '../assets/images/animals/zebra.svg';
import zubr from '../assets/images/animals/zubr.svg';
import zyrafa from '../assets/images/animals/zyrafa.svg';
import bekon from '../assets/images/food/bekon.svg';
import burger from '../assets/images/food/burger.svg';
import gofry from '../assets/images/food/gofry.svg';
import hotdog from '../assets/images/food/hotdog.svg';
import jajko from '../assets/images/food/jajko.svg';
import kanapka from '../assets/images/food/kanapka.svg';
import lody from '../assets/images/food/lody.svg';
import pizza from '../assets/images/food/pizza.svg';
import sok from '../assets/images/food/sok.svg';
import ananas from '../assets/images/fruits/ananas.svg';
import arbuz from '../assets/images/fruits/arbuz.svg';
import banan from '../assets/images/fruits/banan.svg';
import cytryna from '../assets/images/fruits/cytryna.svg';
import gruszka from '../assets/images/fruits/gruszka.svg';
import jablko from '../assets/images/fruits/jablko.svg';
import kiwi from '../assets/images/fruits/kiwi.svg';
import mango from '../assets/images/fruits/mango.svg';
import melon from '../assets/images/fruits/melon.svg';
import pomarancza from '../assets/images/fruits/pomarancza.svg';
import sliwka from '../assets/images/fruits/sliwka.svg';
import truskawka from '../assets/images/fruits/truskawka.svg';
import winogrono from '../assets/images/fruits/winogrono.svg';
import wisnie from '../assets/images/fruits/wisnie.svg';
import fotel from '../assets/images/furniture/fotel.svg';
import komoda from '../assets/images/furniture/komoda.svg';
import krzeslo from '../assets/images/furniture/krzeslo.svg';
import lampa from '../assets/images/furniture/lampa.svg';
import sofa from '../assets/images/furniture/sofa.svg';
import stolik from '../assets/images/furniture/stolik.svg';
import wazon from '../assets/images/furniture/wazon.svg';
import awokado from '../assets/images/vegetables/awokado.svg';
import brokol from '../assets/images/vegetables/brokol.svg';
import cebula from '../assets/images/vegetables/cebula.svg';
import czosnek from '../assets/images/vegetables/czosnek.svg';
import dynia from '../assets/images/vegetables/dynia.svg';
import groszek from '../assets/images/vegetables/groszek.svg';
import kapusta from '../assets/images/vegetables/kapusta.svg';
import kukurydza from '../assets/images/vegetables/kukurydza.svg';
import marchewka from '../assets/images/vegetables/marchewka.svg';
import ogorek from '../assets/images/vegetables/ogorek.svg';
import papryka from '../assets/images/vegetables/papryka.svg';
import pieczarka from '../assets/images/vegetables/pieczarka.svg';
import pomidor from '../assets/images/vegetables/pomidor.svg';
import ziemniak from '../assets/images/vegetables/ziemniak.svg';
import auto from '../assets/images/vehicles/auto.svg';
import autobus from '../assets/images/vehicles/autobus.svg';
import balon from '../assets/images/vehicles/balon.svg';
import helikopter from '../assets/images/vehicles/helikopter.svg';
import lodka from '../assets/images/vehicles/lodka.svg';
import pociag from '../assets/images/vehicles/pociag.svg';
import rakieta from '../assets/images/vehicles/rakieta.svg';
import samolot from '../assets/images/vehicles/samolot.svg';
import statek from '../assets/images/vehicles/statek.svg';
import ufo from '../assets/images/vehicles/ufo.svg';

import {
  Difficulty,
  QuestionDefinition,
} from './types';

const vehiclesEasy: QuestionDefinition[] = [
  {
    image: auto,
    word: 'auto',
  },
  {
    image: autobus,
    word: 'autobus',
  },
  {
    image: balon,
    word: 'balon',
  },
  {
    image: rakieta,
    word: 'rakieta',
  },
  {
    image: samolot,
    word: 'samolot',
  },
  {
    image: statek,
    word: 'statek',
  },
  {
    image: ufo,
    word: 'ufo',
  },
];

const vehiclesMedium: QuestionDefinition[] = [
  {
    image: helikopter,
    word: 'helikopter',
  },
];

const vehiclesHard: QuestionDefinition[] = [
  {
    image: pociag,
    word: 'pociąg',
  },
  {
    image: lodka,
    word: 'łódka',
  },
];

const furnitureEasy: QuestionDefinition[] = [
  {
    image: fotel,
    word: 'fotel',
  },
  {
    image: komoda,
    word: 'komoda',
  },
  {
    image: lampa,
    word: 'lampa',
  },
  {
    image: sofa,
    word: 'sofa',
  },
  {
    image: stolik,
    word: 'stolik',
  },
  {
    image: wazon,
    word: 'wazon',
  },
];

const furnitureHard: QuestionDefinition[] = [
  {
    image: krzeslo,
    word: 'krzesło',
  },
];

const animalsEasy: QuestionDefinition[] = [
  {
    image: kot,
    word: 'kot',
  },
  {
    image: koza,
    word: 'koza',
  },
  {
    image: krowa,
    word: 'krowa',
  },
  {
    image: kura,
    word: 'kura',
  },
  {
    image: lew,
    word: 'lew',
  },
  {
    image: mewa,
    word: 'mewa',
  },
  {
    image: owca,
    word: 'owca',
  },
  {
    image: panda,
    word: 'panda',
  },
  {
    image: paw,
    word: 'paw',
  },
  {
    image: pies,
    word: 'pies',
  },
  {
    image: szop,
    word: 'szop',
  },
];

const animalsMedium: QuestionDefinition[] = [
  {
    image: tygrys,
    word: 'tygrys',
  },
  {
    image: gepard,
    word: 'gepard',
  },
  {
    image: kangur,
    word: 'kangur',
  },
  {
    image: pingwin,
    word: 'pingwin',
  },
  {
    image: hipopotam,
    word: 'hipopotam',
  },
  {
    image: kameleon,
    word: 'kameleon',
  },
  {
    image: krokodyl,
    word: 'krokodyl',
  },
  {
    image: leniwiec,
    word: 'leniwiec',
  },
  {
    image: pancernik,
    word: 'pancernik',
  },
  {
    image: pelikan,
    word: 'pelikan',
  },
  {
    image: szczur,
    word: 'szczur',
  },
  {
    image: tukan,
    word: 'tukan',
  },
];

const animalsHard: QuestionDefinition[] = [
  {
    image: jelen,
    word: 'jeleń',
  },
  {
    image: jez,
    word: 'jeż',
  },
  {
    image: kon,
    word: 'koń',
  },
  {
    image: malpa,
    word: 'małpa',
  },
  {
    image: mrowkojad,
    word: 'mrówkojad',
  },
  {
    image: niedzwiedz,
    word: 'niedźwiedź',
  },
  {
    image: nosorozec,
    word: 'nosorożec',
  },
  {
    image: sep,
    word: 'sęp',
  },
  {
    image: slon,
    word: 'słoń',
  },
  {
    image: strus,
    word: 'struś',
  },
  {
    image: swinia,
    word: 'świnia',
  },
  {
    image: waz,
    word: 'wąż',
  },
  {
    image: wielblad,
    word: 'wielbłąd',
  },
  {
    image: wol,
    word: 'wół',
  },
  {
    image: zebra,
    word: 'zebra',
  },
  {
    image: zubr,
    word: 'żubr',
  },
  {
    image: zyrafa,
    word: 'żyrafa',
  },
];

const foodEasy: QuestionDefinition[] = [
  {
    image: bekon,
    word: 'bekon',
  },
  {
    image: burger,
    word: 'burger',
  },
  {
    image: gofry,
    word: 'gofry',
  },
  {
    image: hotdog,
    word: 'hotdog',
  },
  {
    image: jajko,
    word: 'jajko',
  },
  {
    image: kanapka,
    word: 'kanapka',
  },
  {
    image: lody,
    word: 'lody',
  },
  {
    image: pizza,
    word: 'pizza',
  },
  {
    image: sok,
    word: 'sok',
  },
];

export const fruitsEasy: QuestionDefinition[] = [
  {
    image: ananas,
    word: 'ananas',
  },
  {
    image: arbuz,
    word: 'arbuz',
  },
  {
    image: banan,
    word: 'banan',
  },
  {
    image: cytryna,
    word: 'cytryna',
  },
  {
    image: kiwi,
    word: 'kiwi',
  },
  {
    image: mango,
    word: 'mango',
  },
  {
    image: melon,
    word: 'melon',
  },
];

export const fruitsMedium: QuestionDefinition[] = [
  {
    image: gruszka,
    word: 'gruszka',
  },
  {
    image: truskawka,
    word: 'truskawka',
  },
  {
    image: winogrono,
    word: 'winogrono',
  },
];

export const fruitsHard: QuestionDefinition[] = [
  {
    image: jablko,
    word: 'jabłko',
  },
  {
    image: pomarancza,
    word: 'pomarańcza',
  },
  {
    image: sliwka,
    word: 'śliwka',
  },
  {
    image: wisnie,
    word: 'wiśnie',
  },
];

export const vegetablesEasy: QuestionDefinition[] = [
  {
    image: awokado,
    word: 'awokado',
  },
  {
    image: cebula,
    word: 'cebula',
  },
  {
    image: dynia,
    word: 'dynia',
  },
  {
    image: kapusta,
    word: 'kapusta',
  },
  {
    image: kukurydza,
    word: 'kukurydza',
  },
  {
    image: papryka,
    word: 'papryka',
  },
  {
    image: pomidor,
    word: 'pomidor',
  },
  {
    image: ziemniak,
    word: 'ziemniak',
  },
];

const vegetablesMedium: QuestionDefinition[] = [
  {
    image: czosnek,
    word: 'czosnek',
  },
  {
    image: groszek,
    word: 'groszek',
  },
  {
    image: marchewka,
    word: 'marchewka',
  },
];

export const vegetablesHard: QuestionDefinition[] = [
  {
    image: brokol,
    word: 'brokół',
  },
  {
    image: ogorek,
    word: 'ogórek',
  },
  {
    image: pieczarka,
    word: 'pieczarka',
  },
];

export const random: Record<Difficulty, QuestionDefinition[]> = {
  easy: [
    ...animalsEasy,
    ...vehiclesEasy,
    ...furnitureEasy,
    ...foodEasy,
    ...fruitsEasy,
    ...vegetablesEasy,
  ],
  medium: [
    ...animalsMedium,
    ...vehiclesMedium,
    ...fruitsMedium,
    ...vegetablesMedium,
  ],
  hard: [
    ...animalsHard,
    ...furnitureHard,
    ...fruitsHard,
    ...vehiclesHard,
    ...vegetablesHard,
  ],
};