import { PropsWithChildren } from 'react';
import styled from 'styled-components';

export type DialogProps = PropsWithChildren<{
  isOpen: boolean;
  onClose?: () => void;
}>

export const Dialog = ({ children, isOpen, onClose }: DialogProps) => {
  if (!isOpen) {
    return null;
  }

  return (
    <S.DialogOverlay>
      <S.Dialog>
        {onClose && <S.CloseButton onClick={onClose}>X</S.CloseButton>}
        {children}
      </S.Dialog>
    </S.DialogOverlay>
  );
};

export const DialogActions = styled.div`
  display: flex;
  gap: 2vmin;
  padding: 2vmin 5vmin;
`;

export const DialogContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2vmin 5vmin;
  gap: 1vmin;
  align-items: center;
`;

export const DialogTitle = styled.h2`
  color: #fff;
  font-family: 'Sriracha', cursive;
  font-size: 4.5vmin;
  text-shadow: 0 0.5vmin 0 rgba(0, 0, 0, 0.3);
  margin: 0;
  padding: 2vmin 5vmin;
`;

const S = {
  DialogOverlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    z-index: 100;
  `,
  Dialog: styled.div`
    margin: auto;
    background: #57544c;
    border: 0.5vmin solid #fff;
    color: #fff;
    border-radius: 4vmin;
    display: flex;
    flex-direction: column;
    align-items: center;
  `,
  CloseButton: styled.button``,
};