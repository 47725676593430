import logo from '@/assets/images/logo.svg';
import { backgroundMusic } from '@/common/sounds';
import { Difficulty } from '@/common/types';
import { Button } from '@/components/button';
import { SoundToggle } from '@/components/sound-toggle';
import {
  goToNextScene,
  setDifficulty,
} from '@/redux/game-slice';
import { DialogPreGame } from '@/scenes/start/components/dialog-pre-game';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

export const Start = () => {
  const dispatch = useDispatch();
  const [isDifficultyDialogOpen, setIsDifficultyDialogOpen] = useState(false);
  const startGame = (difficulty: Difficulty) => {
    dispatch(setDifficulty(difficulty));
    dispatch(goToNextScene());
    setIsDifficultyDialogOpen(false);
  };

  const showPreGame = () => {
    setIsDifficultyDialogOpen(true);
    
    if (!backgroundMusic.playing()) {
      backgroundMusic.play();
    }
  };

  return (
    <S.Start>
      <S.Logo src={logo} alt="Literki" />
      <Button onClick={showPreGame} size="lg">
        Start
      </Button>
      <S.Actions>
        <SoundToggle />
      </S.Actions>
      <DialogPreGame isOpen={isDifficultyDialogOpen} onSelectDifficulty={startGame} />
    </S.Start>
  );
};

const S = {
  Logo: styled.img`
    width: 70%;
    filter: drop-shadow(0 1.5vmin 0 rgba(0, 0, 0, 0.4));
    animation: tracking-in-expand-fwd-top 0.8s cubic-bezier(0.215, 0.610, 0.355, 1.000) both;

    @keyframes tracking-in-expand-fwd-top {
      0% {
        transform: translateZ(-700px) translateY(-500px);
        opacity: 0;
      }
      40% {
        opacity: 0.6;
      }
      100% {
        transform: translateZ(0) translateY(0);
        opacity: 1;
      }
    }

  `,
  Start: styled.div`
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    padding: 5vmin;
    justify-content: center;
    gap: 10vmin;
  `,
  Actions: styled.div`
    position: absolute;
    top: 4vmin;
    right: 4vmin;
    display: flex;
    gap: 4vmin;
  `,
};