import { Difficulty } from '@/common/types';

const basic = 'abcdefghijklmnoprstuwyz';
const advanced = `aąbcćdeęfghijklłmnńoóprsśtuwyzźż`;

export const keyboardLayouts = {
  basic,
  advanced,
} as const;

export type KeyboardLayout = keyof typeof keyboardLayouts;

export const difficultyToLayoutMap: Record<Difficulty, KeyboardLayout> = {
  easy: 'basic',
  medium: 'basic',
  hard: 'advanced',
};