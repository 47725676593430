import {
  KeyboardLayout,
  keyboardLayouts,
} from '@/common/keyboard-layouts';
import styled from 'styled-components';
import { Key } from './components/key';

type KeyboardProps = {
  layout?: KeyboardLayout;
}
export const Keyboard = ({ layout = 'advanced' }: KeyboardProps) => {
  const keys = keyboardLayouts[layout];

  return (
    <S.Keyboard>
      {keys.split('').map((letter, i) => (<Key key={i} letter={letter} />))}
    </S.Keyboard>
  );
};

const S = {
  Keyboard: styled.div`
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    gap: 1vmin;
    justify-content: center;
  `,
};