import {
  DragAndDropContext,
  useDragAndDropContextValue,
} from '@/common/drag-and-drop';
import { PropsWithChildren } from 'react';

export const DragAndDropManager = ({ children }: PropsWithChildren<{}>) => {
  const value = useDragAndDropContextValue();

  return (
    <DragAndDropContext.Provider value={value}>
      {children}
    </DragAndDropContext.Provider>
  );
};