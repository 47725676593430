import {
  negativeSound,
  positiveSound,
} from '@/common/sounds';
import {
  useEffect,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import { Cell } from './components/cell';

type InputProps = {
  answer: string;
  hint: Array<string | undefined>;
  onChange: (value: Array<string | undefined>) => void;
  value: Array<string | undefined>;
}

export const Input = ({ answer, hint, value, onChange }: InputProps) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isValidish, setIsValidish] = useState(false);

  const handleChange = (index: number, letter: string) => {
    const newValue = [...value];
    newValue[index] = letter;
    onChange(newValue);
    setIsAnimating(true);
    const isValid = letter === answer[index];
    setIsValidish(isValid);

    if (isValid) {
      positiveSound.play();
    } else {
      negativeSound.play();
    }
  };

  const getValidity = (index: number) => {
    let char = value[index] || hint[index];

    return !!char && char === answer[index];
  };

  useEffect(() => {
    onChange(value);
  }, [hint, onChange, value]);

  const handleAnimationEnd = () => {
    setIsAnimating(false);
  };

  return (
    <S.Input isAnimating={isAnimating} isValidish={isValidish} onAnimationEnd={handleAnimationEnd}>
      {answer.split('').map((letter, i) => (
        <Cell isValid={getValidity(i)} isHint={!!hint[i]} letter={hint[i] || value[i] || ''}
          onChange={(letter: string) => handleChange(i, letter)}
          key={i} />))}
    </S.Input>
  );
};

const S = {
  Input: styled.div<{ isAnimating?: boolean; isValidish?: boolean }>`
    display: flex;
    gap: 1vmin;

    ${({ isAnimating, isValidish }) => isAnimating && css`
      animation: ${isValidish ? 'shake-vertical 0.5s' : 'shake-horizontal 0.7s'} cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
    `};

    @keyframes shake-vertical {
      0%,
      100% {
        transform: translateY(0);
      }

      16% {
        transform: translateY(8px);
      }

      33% {
        transform: translateY(-8px);
      }

      50% {
        transform: translateY(6px);
      }

      66% {
        transform: translateY(-6px);
      }

      82% {
        transform: translateY(4px);
      }
    }

    @keyframes shake-horizontal {
      0%,
      100% {
        transform: translateX(0);
      }
      10%,
      30%,
      50%,
      70% {
        transform: translateX(-10px);
      }
      20%,
      40%,
      60% {
        transform: translateX(10px);
      }
      80% {
        transform: translateX(8px);
      }
      90% {
        transform: translateX(-8px);
      }
    }

  `,
};