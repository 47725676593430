import { Button } from '@/components/button';
import {
  Dialog,
  DialogActions,
  DialogProps,
  DialogTitle,
} from '@/components/dialog';

type DialogLeaveProps = Pick<DialogProps, 'isOpen'> & {
  onEnd: () => void;
  onClose: () => void;
};

export const DialogLeave = ({
  isOpen,
  onClose,
  onEnd,
}: DialogLeaveProps) => {
  return (
    <Dialog isOpen={isOpen}>
      <DialogTitle>Zakończyć grę?</DialogTitle>
      <DialogActions>
        <Button onClick={onClose} color="green">Graj dalej</Button>
        <Button onClick={onEnd} color="red">Wyjdź</Button>
      </DialogActions>
    </Dialog>
  );
};