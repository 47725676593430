import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';

type UseDragProps = {
  item: any,
}

export const useDrag = ({
  item,
}: UseDragProps) => {
  const [targetEl, setTargetEl] = useState<HTMLElement | null>(null);
  const { setCloneEl, isDragging, setIsDragging, setItem } = useContext(DragAndDropContext);

  const onPointerDown = useCallback((event: PointerEvent) => {
    if (event.isPrimary) {
      setIsDragging(true);
      setItem(item);

      if (targetEl) {
        targetEl.releasePointerCapture(event.pointerId);

        const clone = targetEl.cloneNode(true) as HTMLElement;
        clone.style.cssText = `
        position: fixed;
        pointer-events: none;
        transform: translate(-50%, -50%);
        top: ${event.pageY}px;
        left: ${event.pageX}px;
        `;
        document.body.appendChild(clone);
        setCloneEl(clone);
      }
    }
  }, [setCloneEl, item, setIsDragging, setItem, targetEl]);

  useEffect(() => {
    if (targetEl) {
      targetEl.addEventListener('pointerdown', onPointerDown);

      return () => {
        targetEl.removeEventListener('pointerdown', onPointerDown);
      };
    }
  }, [targetEl, onPointerDown]);

  return { isDragging, setTargetEl };
};

type UseDropProps = {
  onDrop: (item: any) => void;
}

export const useDrop = ({
  onDrop,
}: UseDropProps) => {
  const [targetEl, setTargetEl] = useState<HTMLElement | null>(null);
  const { isDragging, item } = useContext(DragAndDropContext);
  const [isOver, setIsOver] = useState(false);

  const onPointerEnter = useCallback((event: PointerEvent) => {
    if (event.isPrimary && isDragging) {
      setIsOver(true);
    }
  }, [isDragging]);

  const onPointerLeave = useCallback((event: PointerEvent) => {
    if (event.isPrimary && isDragging) {
      setIsOver(false);
    }
  }, [isDragging]);

  const onPointerUp = useCallback((event: PointerEvent) => {
    if (event.isPrimary && isDragging) {
      onDrop(item);
      setIsOver(false);
    }
  }, [isDragging, item, onDrop]);

  useEffect(() => {
    if (targetEl) {
      targetEl.addEventListener('pointerenter', onPointerEnter);
      targetEl.addEventListener('pointerleave', onPointerLeave);
      targetEl.addEventListener('pointerup', onPointerUp);

      return () => {
        targetEl.removeEventListener('pointerenter', onPointerEnter);
        targetEl.removeEventListener('pointerleave', onPointerLeave);
        targetEl.removeEventListener('pointerup', onPointerUp);
      };
    }
  }, [onPointerEnter, onPointerLeave, onPointerUp, targetEl]);

  return { isOver, setTargetEl };
};

export const useDragAndDropContextValue = () => {
  const [isDragging, setIsDragging] = useState(false);
  const [item, setItem] = useState<any>();
  const [cloneEl, setCloneEl] = useState<HTMLElement>();

  const onPointerMove = useCallback((event: PointerEvent) => {
    if (event.isPrimary && isDragging && cloneEl) {
      cloneEl.style.left = `${event.pageX}px`;
      cloneEl.style.top = `${event.pageY}px`;
    }
  }, [cloneEl, isDragging]);

  const onPointerUp = useCallback((event: PointerEvent) => {
    if (event.isPrimary && isDragging) {
      setIsDragging(false);
      if (cloneEl) {
        document.body.removeChild(cloneEl);
        setCloneEl(undefined);
      }
    }
  }, [cloneEl, isDragging]);

  useEffect(() => {
    document.addEventListener('pointermove', onPointerMove);
    document.addEventListener('pointerup', onPointerUp);

    return () => {
      document.removeEventListener('pointermove', onPointerMove);
      document.removeEventListener('pointerup', onPointerUp);
    };
  }, [onPointerMove, onPointerUp]);

  return {
    isDragging,
    setIsDragging,
    item,
    setItem,
    setCloneEl,
  };
};

type DragAndDropContextValue = {
  isDragging: boolean;
  setIsDragging: (isDragging: boolean) => void;
  item: any;
  setItem: (item: any) => void;
  setCloneEl: (el: HTMLElement) => void;
}

function noop() {
}

export const DragAndDropContext = createContext<DragAndDropContextValue>({
  isDragging: false,
  setIsDragging: noop,
  item: undefined,
  setItem: noop,
  setCloneEl: noop,
});