import styled from 'styled-components';

type QuestionProps = {
  image: string;
}

export const Question = ({
  image,
}: QuestionProps) => {
  return (
    <S.Question style={{ backgroundImage: `url(${image})` }} />
  );
};

const S = {
  Question: styled.div`
    width: 100%;
    height: 40vmin;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    animation: pulsate 6s ease-in-out infinite both;

    @keyframes pulsate {
      0% {
        transform: scale(1) rotate(-3deg);
      }
      25% {
        transform: scale(1.1) rotate(0deg);
      }
      50% {
        transform: scale(1) rotate(3deg);
      }
      75% {
        transform: scale(1.1) rotate(0deg);
      }
      100% {
        transform: scale(1) rotate(-3deg);
      }
    }

  `,
};