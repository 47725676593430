import background from '@/assets/audio/background-1.mp3';
import click from '@/assets/audio/click.mp3';
import negative from '@/assets/audio/negative.mp3';
import positive from '@/assets/audio/positive.mp3';
import win from '@/assets/audio/win.mp3';
import { Howl } from 'howler';

export const clickSound = new Howl({
  src: [click],
  volume: 0.8,
  html5: true,
});

export const winSound = new Howl({
  src: [win],
  volume: 0.5,
  html5: true,
});

export const negativeSound = new Howl({
  src: [negative],
  volume: 0.2,
  html5: true,
});

export const positiveSound = new Howl({
  src: [positive],
  volume: 0.2,
  html5: true,
});

export const backgroundMusic = new Howl({
  src: [background],
  volume: 0.05,
  html5: true,
  loop: true,
});