import {
  isDebug,
  NUMBER_OF_QUESTIONS_PER_ROUND,
} from '@/common/constants';
import { random } from '@/common/images';
import {
  Difficulty,
  QuestionDefinition,
  SceneName,
} from '@/common/types';
import {
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import shuffle from 'lodash/shuffle';

type GameState = {
  currentQuestion: number;
  difficulty: Difficulty;
  questions: QuestionDefinition[];
  scene: SceneName;
  score: number;
  muted: boolean;
  questionBank: QuestionDefinition[];
}

const initialState: GameState = {
  questionBank: shuffle(random.easy),
  currentQuestion: 0,
  difficulty: 'easy',
  scene: 'start',
  questions: [],
  score: 0,
  muted: false,
};

export const gameSlice = createSlice({
  name: 'game',
  initialState,
  reducers: {
    goToNextScene: (state) => {
      if (state.scene === 'start') {
        state.scene = 'gameplay';
      } else if (state.scene === 'gameplay') {
        state.scene = 'start';
      }
    },
    prepareQuestions: (state) => {
      state.currentQuestion = 0;

      if (isDebug) {
        state.questions = [
          ...random.easy,
          ...random.medium,
          ...random.hard,
        ];
      } else {
        if (state.questionBank.length < NUMBER_OF_QUESTIONS_PER_ROUND) {
          state.questionBank = shuffle(random[state.difficulty]);
        }

        state.questions = state.questionBank.splice(0, NUMBER_OF_QUESTIONS_PER_ROUND);
      }

      state.score = 0;
    },
    nextQuestion: (state) => {
      if (state.currentQuestion < state.questions.length - 1) {
        state.currentQuestion += 1;
      }
    },
    setDifficulty: (state, action: PayloadAction<Difficulty>) => {
      if (action.payload !== state.difficulty) {
        state.questionBank = shuffle(random[action.payload]);
      }

      state.difficulty = action.payload;
    },
    addPoints: (state, action: PayloadAction<number>) => {
      state.score += action.payload;
    },
    toggleMuted: (state, action: PayloadAction<boolean>) => {
      state.muted = action.payload;
    },
  },
});

export const { prepareQuestions, nextQuestion, goToNextScene, setDifficulty, addPoints, toggleMuted } = gameSlice.actions;

export default gameSlice.reducer;