import { MAX_POINTS } from '@/common/constants';
import { Point } from '@/components/point';
import styled from 'styled-components';

type PointsProps = {
  points: number;
}
export const Points = ({ points }: PointsProps) => {
  return (
    <S.Points>
      {new Array(MAX_POINTS).fill(null).map((v, i) => (
        <Point key={i} index={i} filled={i < points} />
      ))}
    </S.Points>
  );
};

const S = {
  Points: styled.div`
    display: flex;
    gap: 1vmin;
  `,

};