import {
  MAX_POINTS,
  MED_POINTS,
  MIN_POINTS,
} from '@/common/constants';

export const getPoints = (hint: Array<string | undefined>, value: Array<string | undefined>) => {
  // no hints used
  if (!hint.some((v) => !!v)) {
    return MAX_POINTS;
  }

  // fewer hints than correct letters
  if (hint.reduce((result, current) => result + (current ? 1 : 0), 0) < value.reduce((result, current) => result + (current ? 1 : 0), 0)) {
    return MED_POINTS;
  }

  return MIN_POINTS;
};