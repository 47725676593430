import { ReactComponent as CloseIcon } from '@/assets/icons/close.svg';
import { ReactComponent as QuestionIcon } from '@/assets/icons/question.svg';
import { isDebug } from '@/common/constants';
import { difficultyToLayoutMap } from '@/common/keyboard-layouts';
import { getPoints } from '@/common/utils';
import { DragAndDropManager } from '@/components/drag-and-drop-manager';
import { IconButton } from '@/components/icon-button';
import { SoundToggle } from '@/components/sound-toggle';
import {
  addPoints,
  goToNextScene,
  nextQuestion,
  prepareQuestions,
} from '@/redux/game-slice';
import {
  currentQuestionSelector,
  difficultySelector,
  questionsSelector,
  scoreSelector,
} from '@/redux/selectors';
import { DialogLeave } from '@/scenes/gameplay/components/dialog-leave';
import { DialogProgress } from '@/scenes/gameplay/components/dialog-progress';
import {
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import styled from 'styled-components';
import { Input } from './components/input';
import { Keyboard } from './components/keyboard';
import { Question } from './components/question';

export const Gameplay = () => {
  const dispatch = useDispatch();

  const difficulty = useSelector(difficultySelector);
  const questions = useSelector(questionsSelector);
  const score = useSelector(scoreSelector);
  const currentQuestion = useSelector(currentQuestionSelector);

  const keyboardLayout = difficultyToLayoutMap[difficulty];
  const [isProgressDialogOpen, setIsProgressDialogOpen] = useState(false);
  const [isLeaveDialogOpen, setIsLeaveDialogOpen] = useState(false);
  const isLastQuestion = currentQuestion === questions.length - 1;
  const answer = questions ? questions[currentQuestion]?.word : '';

  const [gameId, setGameId] = useState(0);
  const [value, setValue] = useState<Array<string | undefined>>([]);
  const [hint, setHint] = useState<Array<string | undefined>>([]);
  const [currentPoints, setCurrentPoints] = useState(0);

  useEffect(() => {
    dispatch(prepareQuestions());
  }, [dispatch]);

  const resetInput = useCallback(() => {
    setHint([]);
    setValue([]);
  }, []);

  const handleNext = useCallback(() => {
    dispatch(nextQuestion());
    setIsProgressDialogOpen(false);
    resetInput();
  }, [dispatch, resetInput]);

  const handleValueChange = useCallback((newValue: Array<string | undefined>) => {
    setValue(newValue);

    const mergedAnswer = answer.split('').map((l, i) => hint[i] || value[i]).join('');

    if (mergedAnswer === answer) {
      setTimeout(() => {
        const points = getPoints(hint, value);
        setCurrentPoints(points);
        dispatch(addPoints(points));
        if (isDebug) {
          handleNext();
        } else {
          setIsProgressDialogOpen(true);
        }
      }, isDebug ? 100 : 700);
    }
  }, [dispatch, answer, handleNext, hint, value]);

  const handleEnd = useCallback(() => {
    dispatch(goToNextScene());
    setIsProgressDialogOpen(false);
    resetInput();
  }, [dispatch, resetInput]);

  const handleNewGame = useCallback(() => {
    dispatch(prepareQuestions());
    setIsProgressDialogOpen(false);
    setGameId(gameId + 1);
    resetInput();
  }, [dispatch, gameId, resetInput]);

  const openLeaveDialog = useCallback(() => {
    setIsLeaveDialogOpen(true);
  }, []);

  const closeLeaveDialog = useCallback(() => {
    setIsLeaveDialogOpen(false);
  }, []);

  const addHint = useCallback(() => {
    const newHint = [...hint];
    let i = 0;

    while (i < answer.length) {
      if (answer[i] !== value[i] && !hint[i]) {
        newHint[i] = answer[i];
        setHint(newHint);
        return;
      }
      i += 1;
    }
  }, [hint, value, answer]);


  if (!questions.length) {
    return null;
  }

  return (
    <S.Gameplay key={gameId}>
      <DragAndDropManager>
        <Question image={questions[currentQuestion].image} />
        <Input answer={answer} value={value} onChange={handleValueChange} hint={hint} />
        <Keyboard layout={keyboardLayout} />
        <DialogProgress
          answer={questions[currentQuestion].word}
          isOpen={isProgressDialogOpen}
          onNext={isLastQuestion ? handleNewGame : handleNext}
          onEnd={handleEnd}
          isLastQuestion={isLastQuestion}
          points={currentPoints}
          score={score}
        />
        <DialogLeave
          isOpen={isLeaveDialogOpen}
          onEnd={handleEnd}
          onClose={closeLeaveDialog}
        />
      </DragAndDropManager>
      <S.Actions>
        <IconButton onClick={addHint} color="blue">
          <QuestionIcon />
        </IconButton>
        <SoundToggle />
        <IconButton onClick={openLeaveDialog} color="red">
          <CloseIcon />
        </IconButton>
      </S.Actions>
    </S.Gameplay>
  );
};


const S = {
  Gameplay: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    width: 100%;
    min-height: 0;
    padding: 5vmin;
    gap: 5vmin;
    max-width: 1600px;
    margin: auto;
  `,
  Actions: styled.div`
    position: absolute;
    top: 4vmin;
    right: 4vmin;
    display: flex;
    gap: 4vmin;
  `,
};