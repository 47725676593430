import { ReactComponent as SpeakerOffIcon } from '@/assets/icons/speaker-off.svg';
import { ReactComponent as SpeakerOnIcon } from '@/assets/icons/speaker-on.svg';
import { IconButton } from '@/components/icon-button';
import { toggleMuted } from '@/redux/game-slice';
import { mutedSelector } from '@/redux/selectors';
import { Howler } from 'howler';
import { useCallback } from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

export const SoundToggle = () => {
  const dispatch = useDispatch();

  const muted = useSelector(mutedSelector);

  const toggleMute = useCallback(() => {
    Howler.mute(!muted);
    dispatch(toggleMuted(!muted));
  }, [dispatch, muted]);

  return (
    <IconButton onClick={toggleMute}>
      {muted ? <SpeakerOffIcon /> : <SpeakerOnIcon />}
    </IconButton>
  );
};