import { useDrop } from '@/common/drag-and-drop';
import { BaseKey } from '@/scenes/gameplay/components/base-key';
import styled from 'styled-components';

type CellProps = {
  isHint: boolean;
  isValid: boolean;
  letter?: string;
  onChange: (letter: string) => void;
}

export const Cell = ({ isHint, isValid, letter, onChange }: CellProps) => {
  const { isOver, setTargetEl } = useDrop({
    onDrop: (item) => {
      if (isValid || isHint) {
        return;
      }

      onChange(item.letter);
    },
  });

  return (
    <S.Cell isOver={isOver} ref={(el) => setTargetEl(el)}>
      {letter && <S.BaseKey isHint={isHint} isValid={isValid} letter={letter} isOver={isOver && !isValid} />}
    </S.Cell>
  );
};

const getBackgroundColor = (isValid?: boolean, isHint?: boolean) => {
  if (isHint) {
    return '#fdf1a0';
  }

  return isValid ? '#64df6e' : '#ef9c8f';
};

const S = {
  Cell: styled.div<{ isOver?: boolean, isCorrect?: boolean }>`
    width: 8vmin;
    height: 8vmin;
    border-radius: 2vmin;
    background: ${({ isOver }) => isOver ? '#fdf1a0' : '#fff'};
    filter: drop-shadow(0 0.75vmin 0 rgba(0, 0, 0, 0.3));
  `,
  BaseKey: styled(BaseKey)<{ isHint?: boolean; isValid?: boolean; isOver?: boolean }>`
    background: ${({ isValid, isHint }) => getBackgroundColor(isValid, isHint)};
    opacity: ${({ isOver }) => isOver ? 0.5 : 1};
    filter: none;
  `,
};