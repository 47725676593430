import { ReactComponent as StarFilled } from '@/assets/icons/star-filled.svg';
import { ReactComponent as Star } from '@/assets/icons/star.svg';
import styled from 'styled-components';

type PointProps = {
  filled?: boolean;
  index?: number;
}

export const Point = ({ filled, index = 0 }: PointProps) => {
  return (
    <S.Point style={{ animationDelay: `${index * 0.4}s` }}>
      {filled ? <StarFilled /> : <Star />}
    </S.Point>
  );
};

const S = {
  Point: styled.div`
    width: 6vmin;
    height: 6vmin;
    filter: drop-shadow(0 0.5vmin 0 rgba(0, 0, 0, 0.3));
    animation: fade-in 0.4s both;

    @keyframes fade-in {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    svg {
      width: 100%;
      height: 100%;
    }

    path {
      fill: #ffcc00;
    }
  `,
};