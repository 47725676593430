import { forwardRef } from 'react';
import styled, { css } from 'styled-components';

type BaseKeyProps = {
  className?: string;
  isDraggable?: boolean;
  isDragging?: boolean;
  letter: string;
};

export const BaseKey = forwardRef<HTMLDivElement, BaseKeyProps>(({ className, isDraggable, isDragging, letter }, ref) => {
  return (
    <S.BaseKey className={className} ref={ref} isDraggable={isDraggable} isDragging={isDragging}>
      {letter}
    </S.BaseKey>
  );
});

const S = {
  BaseKey: styled.div<{ isDragging?: boolean; isDraggable?: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 5vmin;
    background: rgb(255, 250, 210);
    background: radial-gradient(circle, rgba(255, 250, 210, 1) 0%, rgba(241, 230, 150, 1) 75%, rgba(255, 246, 193, 1) 100%);
    border-radius: 2vmin;
    filter: drop-shadow(0 0.75vmin 0 rgba(0, 0, 0, 0.3));
    color: #5b3e25;
    width: 8vmin;
    height: 8vmin;
    text-transform: capitalize;
    opacity: ${({ isDragging }) => isDragging ? '0.4' : '1'};
    transform: translate(0, 0);


    ${({ isDraggable }) => isDraggable && css`
      cursor: grab;

      &:hover {
        background: rgb(255, 250, 210);
      }
    `};
  `,
};