import { backgroundMusic } from '@/common/sounds';
import { Start } from '@/scenes/start';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { sceneSelector } from './redux/selectors';
import { Gameplay } from './scenes/gameplay';

export const App = () => {
  const scene = useSelector(sceneSelector);

  useEffect(() => {
    backgroundMusic.play();
  }, []);

  return (
    <S.App>
      {scene === 'start' && <Start />}
      {scene === 'gameplay' && <Gameplay />}
    </S.App>
  );
};


const S = {
  App: styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background: rgb(29, 171, 88);
    background: radial-gradient(circle, rgba(29, 171, 88, 1) 0%, rgba(50, 121, 28, 1) 67%, rgba(23, 94, 20, 1) 100%);
  `,
};