import { clickSound } from '@/common/sounds';
import { PropsWithChildren } from 'react';
import styled, { css } from 'styled-components';

type ButtonColor = 'default' | 'red' | 'green';

type ButtonSize = 'md' | 'lg';

type StyledButtonProps = {
  color?: ButtonColor;
  size?: ButtonSize;
}

type ButtonProps = PropsWithChildren<StyledButtonProps & {
  onClick?: () => void;
}>;

export const Button = ({
  color,
  size = 'md',
  onClick,
  ...props
}: ButtonProps) => {
  const handleClick = () => {
    clickSound.play();
    onClick?.();
  };

  return (
    <S.ButtonWrapper size={size}>
      <S.Button size={size} color={color} onClick={handleClick} {...props} />
    </S.ButtonWrapper>
  );
};

const getBackgroundColor = (color: ButtonColor = 'default') => {
  if (color === 'red') {
    return '#cc1616';
  }

  if (color === 'green') {
    return '#2cb247';
  }

  return '#e68713';
};

const getShadowColor = (color: ButtonColor = 'default') => {
  if (color === 'red') {
    return '#901919';
  }

  if (color === 'green') {
    return '#207832';
  }

  return '#8d5c1e';
};

const S = {
  ButtonWrapper: styled.div<{ size: ButtonSize }>`
    background: rgba(0, 0, 0, 0.6);

    ${({ size }) => size === 'lg' && css`
      padding: 0 0.5vmin 1.5vmin 0.5vmin;
      border-radius: 2.5vmin;
    `};

    ${({ size }) => size === 'md' && css`
      padding: 0 0.3vmin 0.9vmin 0.3vmin;
      border-radius: 1.25vmin;
    `};
  `,
  Button: styled.button<StyledButtonProps>`
    display: block;
    color: #fff;
    cursor: pointer;
    font-family: 'Sriracha', cursive;
    border: none;
    position: relative;

    ${({ color, size }) => css`
      background-color: ${getBackgroundColor(color)};

      ${size === 'lg' && css`
        text-shadow: 0 0.5vmin 0 rgba(0, 0, 0, 0.3);
        padding: 1vmin 5vmin;
        font-size: 5vmin;
        border-radius: 2vmin;
        margin-top: -0.5vmin;
        box-shadow: 0 1vmin 0 ${getShadowColor(color)};

        &:hover {
          margin-top: 0;
          box-shadow: 0 0.5vmin 0 ${getShadowColor(color)};
          margin-bottom: -0.5vmin;
        }
      `};

      ${size === 'md' && css`
        text-shadow: 0 0.3vmin 0 rgba(0, 0, 0, 0.3);
        padding: 0.75vmin 3vmin;
        font-size: 3vmin;
        border-radius: 1vmin;
        margin-top: -0.25vmin;
        box-shadow: 0 0.6vmin 0 ${getShadowColor(color)};

        &:hover {
          margin-top: 0;
          box-shadow: 0 0.3vmin 0 ${getShadowColor(color)};
          margin-bottom: -0.25vmin;
        }
      `};
    `};
  `,
};