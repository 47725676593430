import { clickSound } from '@/common/sounds';
import { PropsWithChildren } from 'react';
import styled from 'styled-components';

type IconButtonColor = 'red' | 'blue'

type IconButtonProps = PropsWithChildren<{
  color?: IconButtonColor;
  onClick?: () => void;
}>

export const IconButton = ({ color, children, onClick }: IconButtonProps) => {
  const handleClick = () => {
    clickSound.play();
    onClick?.();
  };

  return (
    <S.IconButton color={color} onClick={handleClick}>
      <S.Icon>
        {children}
      </S.Icon>
    </S.IconButton>
  );
};

const getBackgroundColor = (color?: IconButtonColor) => {
  if (color === 'red') {
    return '#cc1616';
  }

  if (color === 'blue') {
    return '#1762c1';
  }

  return '#e68713';
};

const S = {
  IconButton: styled.button<{ color?: IconButtonColor }>`
    display: block;
    color: #fff;
    cursor: pointer;
    padding: 0.5vmin;
    border-radius: 50%;
    border: none;
    background: ${({ color }) => getBackgroundColor(color)};
    filter: drop-shadow(0 0.5vmin 0 rgba(0, 0, 0, 0.3));
    box-shadow: 0 0.4vmin inset rgba(0, 0, 0, 0.3);
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: -0.5vmin;
      bottom: -0.5vmin;
      left: -0.5vmin;
      right: -0.5vmin;
      border-radius: 50%;
      border: 0.5vmin solid white;
    }
  `,
  Icon: styled.div`
    width: 4vmin;
    height: 4vmin;
    filter: drop-shadow(0 0.4vmin 0 rgba(0, 0, 0, 0.3));

    > svg {
      width: 100%;
      height: 100%;
    }
  `,
};