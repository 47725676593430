import { useDrag } from '@/common/drag-and-drop';
import { BaseKey } from '@/scenes/gameplay/components/base-key';

type KeyProps = {
  letter: string;
};
export const Key = ({ letter }: KeyProps) => {
  const { isDragging, setTargetEl } = useDrag({
    item: {
      letter,
    },
  });

  return (
    <BaseKey ref={(el) => setTargetEl(el)} letter={letter} isDragging={isDragging} isDraggable={true} />
  );
};

